import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import Keycloak from 'keycloak-js'

Vue.use(VueRouter)
Vue.config.productionTip = false

const router = new VueRouter({
  mode: 'history',
  routes: [
    {path: '/:room', component: App},
  ],
})

const initApp = () => {
  fetch('/api/config')
    .then(res => res.json())
    .then(config => {
      const app = new Vue({
        vuetify,
        router,
        render: h => h(App, {props: {keycloak: keycloak, config: config}})
      })
      app.$mount('#app')
    })
}

function getCookie(id) {
   let value = document.cookie.match('(^|;)?' + id + '=([^;]*)(;|$)');
   return value ? unescape(value[2]) : null;
}

function setCookie(id, value) {
   document.cookie = id + '=' + value;
}

const keycloak = new Keycloak('/api/keycloak.json')
const user_is_logged = getCookie("logged_in_sso");

if (user_is_logged == "true") {
  keycloak.init({onLoad: 'check-sso', checkLoginIframe: false})
    .then(() => {
      setCookie("logged_in_sso", "true")
      initApp()
    })
    .catch(() => {
      alert('Did you configure CORS correctly?')
      initApp()
    })
} else {
  initApp()
}
