<template>
  <v-app>
    <v-app-bar
        app
        color="primary"
        dark
    >
      <v-toolbar-title>Jitsi Keycloak</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-img v-if="profile.avatar" :src=profile.avatar max-height="40" max-width="40"
             class="mx-2 rounded-avatar"></v-img>
      <span v-if="authenticated">{{ profile.firstName }} {{ profile.lastName }}</span>
      <v-tooltip bottom v-if="authenticated">
        <template v-slot:activator="{ on }">
          <v-btn icon @click="keycloak.logout({redirectUri: url})" v-on="on">
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </template>
        <span>Logout</span>
      </v-tooltip>
    </v-app-bar>
    <v-main>
      <v-container>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>Login</v-card-title>
              <v-card-text>
                <v-form
                    v-if="authenticated"
                    ref="form"
                    v-model="valid"
                    @submit.prevent="loginSSO">
                  <v-row>
                    <v-col>
                      <v-text-field v-model="firstName" :rules="[rules.required]" label="First Name"
                                    disabled></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field v-model="lastName" :rules="[rules.required]" label="Last Name"
                                    disabled></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field v-model="room" :rules="[rules.required]" label="Room" autofocus
                                    :disabled="roomViaPath"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-btn :disabled="!roomValid" type="submit" color="primary" class="mr-4">
                    Войти через Selectel SSO
                  </v-btn>
                </v-form>
                <v-form
                    v-else
                    ref="form"
                    v-model="valid"
                    @submit.prevent="loginGuest">
                  <v-row>
                    <v-col>
                      <v-text-field v-model="firstName" :rules="[rules.required]" label="First Name"
                                    autofocus></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field v-model="lastName" :rules="[rules.required]" label="Last Name"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field v-model="room" :rules="[rules.required]" label="Room"
                                    :disabled="roomViaPath"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-btn :disabled="!valid" type="submit" color="success" class="mr-4">
                    Войти как Гость
                  </v-btn>
                  <v-btn :disabled="!roomValid" color="primary" class="mr-4" @click="loginSSO">
                    Войти через Selectel SSO
                  </v-btn>
                  <v-btn color="primary" class="mr-4" @click="loginMeetingRoom">
                    Подключить переговорку
                  </v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

function setCookie(id, value) {
       document.cookie = id + '=' + value;
}
export default {
  name: 'App',

  props: ['keycloak', 'config'],
  computed: {
    url() {
      return window.location.href
    },
  },
  data: () => ({
    authenticated: false,
    profile: {
      firstName: null,
      lastName: null,
      avatar: null,
    },
    valid: false,
    firstName: null,
    lastName: null,
    room: null,
    roomValid: false,
    roomViaPath: false,
    rules: {
      required: value => !!value || 'Required',
    },
  }),
  watch: {
    room: 'checkRoom',
  },
  methods: {
    checkRoom() {
      this.roomValid = !!this.room
    },
    loginGuest() {
      let payload = {
        firstName: this.firstName,
        lastName: this.lastName,
        room: this.room,
      }

      this.fetchGuestToken(payload)
          .then(data => {
            window.location.href = this.buildLink(this.room, data.token)
          })
          .catch(error => {
            console.error('could not guest login', error)
          })
    },
    loginSSO() {
      let payload = {
        room: this.room,
      }
      if (this.authenticated) {
        this.fetchToken(payload)
            .then(data => {
              window.location.href = this.buildLink(this.room, data.token)
            })
            .catch(error => {
              console.error('could not SSO login', error)
            })
      } else {
        this.keycloak.init({onLoad: 'login-required', checkLoginIframe: false})
        setCookie("logged_in_sso", "true");
      }
    },
    loginMeetingRoom() {
      let payload = {
        firstName: 'Meeting',
        lastName: 'Room',
        room: this.room,
      }

      this.fetchGuestToken(payload)
          .then(data => {
            window.location.href = this.buildLink(this.room, data.token)
          })
          .catch(error => {
            console.error('could not guest login', error)
          })
    },
    fetchGuestToken(payload) {
      return fetch('/api/guest-token', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(payload),
      })
          .then(res => {
            if (!res.ok) {
              throw new Error(`error while request "${res.url}": status=${res.status}`)
            }
            return res.json()
          })
    },
    fetchToken(payload) {
      return this.keycloak.updateToken(10)
          .then(() => {
            return fetch('/api/token', {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${this.keycloak.token}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(payload),
            })
                .then(res => {
                  if (!res.ok) {
                    throw new Error(`error while request "${res.url}": status=${res.status}`)
                  }
                  return res.json()
                })
          })
    },
    buildLink(room, token) {
      const tokenUrl = new URL(room, this.config.jitsiUrl)
      tokenUrl.searchParams.append('jwt', token)
      return `${encodeURI(tokenUrl.href)}#?config.prejoinPageEnabled=false&config.prejoinConfig.enabled=false`
    },
  },
  mounted() {
    this.room = this.$route.params.room || this.config.defaultRoom
    this.roomViaPath = Boolean(this.$route.params.room)

    if (this.keycloak.authenticated) {
      this.authenticated = true

      let tokenInfo = this.keycloak.tokenParsed
      this.profile.firstName = tokenInfo.given_name
      this.profile.lastName = tokenInfo.family_name
      this.profile.avatar = tokenInfo.avatar || null

      this.firstName = tokenInfo.given_name
      this.lastName = tokenInfo.family_name
    }

    if (this.config.ssoAutoJoin && this.authenticated && this.$route.params.room) {
      this.loginSSO()
    }
  },
}
</script>

<style>
html {
  overflow-y: auto !important;
}

.rounded-avatar {
  border-radius: 50%;
}
</style>
